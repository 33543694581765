/* eslint-disable */
import instance from "@/services/instance.js";

//have to change instANCE
const CreatePlan = payload => {
  return instance.instance
    .post("/stripeSubscriptionPlan", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const GetAllPlan = payload => {
  return instance.instance
    .get("/stripeSubscriptionPlan/all", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const GetAllTransactions = payload => {
  return instance.instanceToken
    .get("/transaction/all", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const SendMail = payload => {
  return instance.instanceToken
    .post("/v2/payment/sendMail", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const SetPaymentApproval = payload => {
  return instance.instanceToken
    .post("/v2/payment/directPaymentApproval", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const GetAllDirPayments = payload => {
  return instance.instanceToken
    .get("/transaction/direct", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

export default {
  createPlan: CreatePlan,
  getAllPlan: GetAllPlan,
  getAllTransactions: GetAllTransactions,
  sendMail: SendMail,
  getAllDirPayments: GetAllDirPayments,
  setPaymentApproval: SetPaymentApproval
};
